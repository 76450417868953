<template>
  <div class="header-container">
    <div class="search">
      <el-select
        v-model="filters.platformId"
        size="small"
        :placeholder="t('fields.platform')"
        class="filter-item"
        style="width: 120px;"
        clearable
      >
        <el-option
          v-for="platform in uiControl.platform"
          :key="platform.id"
          :label="platform.name"
          :value="platform.id"
        />
      </el-select>

      <el-select
        v-model="filters.sportType"
        size="small"
        :placeholder="t('fields.sportType')"
        class="filter-item"
        style="width: 240px;"
        clearable
      >
        <el-option
          v-for="sport in sports.list"
          :key="sport"
          :label="sport"
          :value="sport"
        />
      </el-select>

      <el-button
        icon="el-icon-search"
        size="mini"
        type="success"
        style="margin-left: 20px"
        @click="loadBetTypes"
      >
        {{ t('fields.search') }}
      </el-button>

      <el-button
        icon="el-icon-refresh"
        size="mini"
        type="warning"
        @click="resetFilters"
      >
        {{ t('fields.reset') }}
      </el-button>
    </div>

    <div class="btn-group" :hidden="true">
      <el-button icon="el-icon-plus" size="mini" type="primary" @click="showDialog('CREATE')">
        {{ t('fields.add') }}
      </el-button>
    </div>

    <el-table
      :data="betTypes"
      border
      stripe
      style="width: 100%; margin-top: 10px;"
      v-loading="loading"
      :empty-text="t('fields.noData')"
    >
      <el-table-column prop="tfBetTypeId" :label="t('fields.betTypeId')" width="100" />
      <el-table-column prop="description" :label="t('fields.betTypeName')" />
      <el-table-column prop="status" :label="t('fields.status')" width="120">
        <template #default="scope">
          <el-switch
            v-model="scope.row.status"
            :active-value="true"
            :inactive-value="false"
            @change="handleStatusChange(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue';
import { getBetTypeSettings, updateBetTypeStatus, getSportTypes } from "@/api/sport-event";
import { ElMessage } from 'element-plus';
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const betTypes = ref([]);
const loading = ref(false);

const filters = reactive({
  platformId: null,
  sportType: null,
});

// const platforms = reactive({ list: [] });
const sports = reactive({ list: [] });
const uiControl = reactive({
  platform: [
    { name: 'IM', display: 'IM', id: 1 }
  ]
});

async function loadSportTypes() {
  const { data } = await getSportTypes();
  sports.list = data;
}

async function loadBetTypes() {
  if (!filters.platformId || !filters.sportType) {
    ElMessage({ message: t('message.validateAllSearchCondition'), type: 'error' });
    return;
  }
  loading.value = true;
  const { data } = await getBetTypeSettings({
    platformId: filters.platformId,
    tfSportType: filters.sportType,
  });
  betTypes.value = data;
  loading.value = false;
}

function resetFilters() {
  filters.platformId = null;
  filters.sportType = null;
  betTypes.value = [];
}

async function handleStatusChange(row) {
  console.log(row)
  const query = {
    platformId: row.platformId,
    tfSportId: row.tfSportId,
    tfBetTypeId: row.tfBetTypeId,
    status: row.status
  };

  await updateBetTypeStatus(query);
  ElMessage.success(t('message.updateSuccess'));
}

onMounted(() => {
  // loadPlatforms();
  loadSportTypes();
});
</script>
<style scoped>
</style>
